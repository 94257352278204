<template>
    <div class="text-left">
        <div class="row">
            <div class="col-6">
            </div>
            <div class="col-6 row">
                <div class="col-12 col-sm-3 offset-sm-4 pt-2">
                    <div class="text-right">
                        <b-button variant="outline-primary" @click="generateReport">
                            Download Report
                        </b-button>
                    </div>
                </div>
                <div class="col-12 col-sm-5">
                    <b-form-input type="text" v-on:keyup.enter="getData()" placeholder="Enter Username or email and press enter" v-model="pagination.username" />
                </div>
            </div>
        </div>
        <b-table :items="memberships.data" :fields="fields">
            <template #cell(user)="row">
                {{ row.item.user ? row.item.user.username : '' }}
            </template>
            <template #cell(membership)="row">
                {{ row.item.membership ? row.item.membership.name : '' }}
            </template>
            <template #cell(price)="row">
                ${{ coinFormat(row.item.membership ? row.item.membership.price : '') }}
            </template>
            <template #cell(description)="row">
                {{ row.item.title}}
            </template>
            <template #cell(payment)="row">
                {{ row.item.payment_method  }}
            </template>
            <template #cell(available)="row">

                <span class="text-success">
                    ${{ coinFormat(row.item.availabel) }}
                </span>
            </template>
            <template #cell(date)="row">
                {{ format_date(row.item.created_at) }}
            </template>
            <template #cell(end)="row">
                {{ format_date(row.item.end) }}
            </template>
            <template #cell(status)="row">

                <div class="badge-danger-special" v-if="row.item.status == 0">Finish</div>
                <div class="badge-success-special" v-if="row.item.status == 1">Active</div>
            </template>
        </b-table>
        <div class="text-right" v-if="memberships.meta">
            <b-button-group>
                <b-button variant="outline-primary" class="mr-3" :disabled="memberships.meta.current_page == memberships.meta.first_page" @click="pagination.page = pagination.page  - 1">Previous</b-button>
                <b-button variant="outline-primary" :disabled="memberships.meta.current_page == memberships.meta.last_page" @click="pagination.page = pagination.page  + 1">Next</b-button>
            </b-button-group>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
export default {
    data () {
        return {
            loading: false,
            fields: ['user', 'membership', 'price', 'end', 'date', 'status', 'available'],
            pagination: {
                username: null,
                page: 1
            },
            paginate: {
                current_page: 0,
                first_page: 0,
                last_page: 0
            }
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('membership', ['getMembershipsActiveAdmin', 'downloadFile']),
        getData() {
            this.loading = true
            if(this.pagination.username == '') {
                this.pagination.username = null
            }
            this.getMembershipsActiveAdmin(this.pagination).then(response => {
                this.paginate = response
                this.loading = false
            })
        },
        generateReport () {
            this.loading = true
            this.downloadFile(this.pagination).then(response => {
                this.loading = false
                window.open(`https://myelv.apimastercode.com/purchases.xlsx`, '_blank')
            })  
        },
        format_date(data) {
            return moment(data).format('Y-MM-DD')
        },
        coinFormat(x) {
            if(x >= 0) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return 0
            }
        }
    },
    computed: {
        ...mapState('membership', ['memberships']),
        ...mapState('cart', ['carts'])
    },
    watch: {
        'pagination.type': function (val) {
            this.pagination.type = val
            this.getData()
        },
        'pagination.page': function (val) {
            this.pagination.page = val
            this.getData()
        }
    }
}
</script>
<style>
    .badge-success-special{
        background: rgba(14, 197, 100, 0.05);
        text-align: center;
        border-radius: 8px;
        padding: 5px;
        color: #0EC564;
        max-width: 100px;
    }
    .badge-danger-special{
        background: rgba(255, 171, 27, 0.05);
        text-align: center;
        border-radius: 8px;
        padding: 5px;
        color: #E3C87A;
        max-width: 100px;
    }
</style>