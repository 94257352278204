<template>
    <div class="h-100">
        <b-card class="fix-image h-100" :class="bgColor">
            <!-- <b-card-img :src="membership.image" alt="Image" class="rounded-0"/> -->
                <div class="plan-name">
                    {{membership.name}}
                </div>
                <div class="plan-price">
                    <span>${{ membership.price }}</span>/usd
                </div>
                <List class="mt-3 mb-4" :benefits="membership.benefits" />
        </b-card>
    </div>
</template>
<script>
import List from './List.vue'
export default {
    props: ['membership', 'color'],
    components: {
        List
    },
    data () {
        return {
            modalPurchase: false
        }
    },
    methods: {
        calcChart(price) {
            let chartArray = [price]
            chartArray.unshift(price * 0.36)
            chartArray.unshift(price * 0.36)
            chartArray.unshift(price * 0.07)

            return chartArray
        },
        splitBread (name) {
            return name.replace(/-/g, ' ');
        },
        goToPurchase() {
            this.modalPurchase = false
            this.$router.push({ name: 'Purchase-Membership', params: { id: this.membership.id, price: this.membership.price } })
        }
    },
    computed: {
        bgColor () {
            if(this.color == '0') {
                return 'bg-first'
            } else if(this.color == '1') {
                return 'bg-second'
            } else if(this.color == '2') {
                return 'bg-third'
            } else if(this.color == '3') {
                return 'bg-four'
            }
        }
    }
}
</script>
<style scoped>
    .plan-name{
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        color: #f0d981;
    }
    .plan-price{
        font-weight: 300;
        font-size: 26px;
        line-height: 40px;
    }
    .plan-price span {
        font-weight: 700;
        font-size: 30px;
        line-height: 48px;
    }
    .image-container{
       width: 100%;
       height: 300px;
       position: relative;
       border-radius: 40px;
    }
    .text-name{
        background: linear-gradient(180deg, rgba(255, 210, 82, 0.95) 0%, rgba(240, 127, 79, 0.95) 100%);
        box-shadow: inset 0px 1.33767px 8.91778px rgba(247, 147, 26, 0.1), inset 0px -1.33767px 8.91778px rgba(247, 147, 26, 0.1);
        color: #000;
        border-radius: 0px 40px 0px 40px;
        float: left;
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: 1000;
        padding: 10px 30px;
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
    }
    .link-text{
        text-decoration: underline;
    }
    .fix-image img{
        max-height: 300px;
    }
    .bg-first{
        background: linear-gradient(90deg, #94395B 0%, #1A160D 100%) !important; 
    }
    .bg-second{
        background: linear-gradient(90deg, #698B32 0%, #1A1E0D 100%) !important;
    }
    .bg-third{
        background: linear-gradient(90deg, #8D7734 0%, #0C181A 100%) !important;
    }
    .bg-four{
        background: linear-gradient(90deg, #26808D 0%, #1D0E14 100%) !important;
    }
</style>