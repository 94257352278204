<template>
    <b-card>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-5">
                <b-card>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis architecto illum dolores omnis totam iure iusto debitis, ea sit animi, at laboriosam! Nesciunt suscipit quae voluptatum, et accusamus maiores sit.
                </b-card>
            </div>
            <div class="col-12 col-sm-12 col-md-7">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis voluptatem minus, inventore modi praesentium, quam aperiam reprehenderit explicabo dolorem minima quos error eveniet ipsa in et, tenetur consequatur. Magni, aliquam!
            </div>
        </div>
    </b-card>
</template>