<template>
    <div>
        <section class="mb-4">
            <b-card>
                <div class="row">
                    <div class="col-12 col-md-5 mb-3">
                        <MainCard />
                    </div>
                    <div class="col-12 col-md-7 mb-3">
                        <Chart />
                    </div>
                </div>
            </b-card>
        </section>
        <section class="no-tab-bottom">
            <b-tabs content-class="mt-3">
                <b-tab title="Memberships" active>
                    <!-- <div class="text-right mb-2">
                        <b-button variant="primary" size="lg" @click="modalCreate = !modalCreate">
                            Create membership
                        </b-button>
                    </div> -->
                    <div v-if="loading == false">
                            <div class="row" v-if="memberships.length > 0">
                                <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3" v-for="(membership, index) in memberships" :key="membership.id">
                                    <CardPackage :membership="membership" :color="index" />
                                </div>
                            </div>
                            <div v-else>
                                <Empty />
                            </div>
                    </div>
                        <div v-else>
                            <Loading type="card" />
                        </div>
                </b-tab>
                <b-tab title="Acquired" lazy>
                    <Purchases :key="calcRandom()" />
                </b-tab>
            </b-tabs>
        </section>
        <b-modal title="Creating membership" hide-footer centered size="lg" v-model="modalCreate">
            <Form @close="modalCreate = false" />
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import CardPackage from './CardPackage.vue'
import Stats from './Stats.vue'
import Form from './Form.vue'
import MainCard from './MainCard.vue'
import Chart from './Chart.vue'
import Purchases from './Purchases.vue'
export default {
    components: {
        Stats,
        CardPackage,
        Form,
        Chart,
        MainCard,
        Purchases
    },
    data () {
        return {
            loading: false,
            modalCreate: false
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('membership', ['getMembershipsAdmin']),
        getData () {
            this.loading = true
            this.getMembershipsAdmin().then(() => {
                this.loading = false
            })
        },
        calcRandom() {
            return Math.random();

        }
    },
    computed: {
        ...mapState('membership', ['memberships'])
    }
}
</script>
<style>
    .no-tab-bottom .nav{
        height: auto !important;
    }
</style>