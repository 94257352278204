<template>
    <div>
        <div class="row">
            <div class="col-12 col-md-8">
                <span>Name</span>
                <b-form-input v-model="form.name"/>
            </div>
            <div class="col-12 col-md-4">
                <span>Price</span>
                <b-form-input v-model="form.price"/>
            </div>
            <div class="col-12 pt-4">
                <span>Upload image</span>
                <input
                type="file"
                class="form-control rounded-xs"
                ref="image"
                placeholder="Your Password"
                @change="changeFiles"
                />
            </div>
            <div class="col-12 col-sm-6 pt-4">
                <b-form-group label="Pay Montly">
                    <b-form-radio-group>
                        <b-form-radio v-model="form.payment_monthly" :aria-describedby="ariaDescribedby" name="some-radios1" value="1">Enable </b-form-radio>
                        <b-form-radio v-model="form.payment_monthly" :aria-describedby="ariaDescribedby" name="some-radios1" value="0">Disable</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <div class="col-12 col-sm-6 pt-4">
                <b-form-group label="Enable Membership">
                    <b-form-radio-group>
                        <b-form-radio v-model="form.is_enable" :aria-describedby="ariaDescribedby" name="some-radios" value="1">Enable </b-form-radio>
                        <b-form-radio v-model="form.is_enable" :aria-describedby="ariaDescribedby" name="some-radios" value="0">Disable</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
            <hr>
            <h4>
                Available modules
            </h4>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <b-form-checkbox
                        v-model="form.benefits"
                        value="Trading-Plans"
                        unchecked-value="not_accepted"
                    >
                        Trading Plans
                    </b-form-checkbox>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <b-form-checkbox
                        v-model="form.benefits"
                        value="Trading-Live"
                        unchecked-value="not_accepted"
                    >
                        Trading Live
                    </b-form-checkbox>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <b-form-checkbox
                        v-model="form.benefits"
                        value="Signals"
                        unchecked-value="not_accepted"
                    >
                        Signals
                    </b-form-checkbox>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <b-form-checkbox
                        v-model="form.benefits"
                        value="Academy"
                        unchecked-value="not_accepted"
                    >
                        Academy
                    </b-form-checkbox>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <b-form-checkbox
                        v-model="form.benefits"
                        value="Wallets"
                        unchecked-value="not_accepted"
                    >
                        Wallets
                    </b-form-checkbox>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <b-form-checkbox
                        v-model="form.benefits"
                        value="Chat"
                        unchecked-value="not_accepted"
                    >
                        Chat
                    </b-form-checkbox>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <b-form-checkbox
                        v-model="form.benefits"
                        value="Transaction Book"
                        unchecked-value="not_accepted"
                    >
                        Transaction-Book
                    </b-form-checkbox>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <b-form-checkbox
                        v-model="form.benefits"
                        value="Referral"
                        unchecked-value="not_accepted"
                    >
                        Referral Benefits
                    </b-form-checkbox>
                </div>
            </div>
            <div class="text-right mt-3">
                <b-button variant="secondary" class="mr-3">
                    Cancel
                </b-button>
                <ButtonAction :loading="loading" loadingTx="Uploading membership" title="Create Membership" @click="onSubmit" v-if="!membership"/>
                <ButtonAction :loading="loading" loadingTx="Uploading membership" title="Update Membership" @click="onUpdate" v-else/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: ['membership'],
    data () {
        return {
            form: {
                benefits: [],
                name: null,
                price: null,
                months: 12,
                payment_monthly: 0,
                is_enable: 1,
                period: 12,
                image: null
            },
            loading: false,
            modules: [
                'Staking',
                'Academy',
                'Trading-Live',
                'Trading-Plans',
                'Signal',
                'Team',
                'Wallet',
                'Chat',
                'Tokens'
            ]
        }
    },
    created () {
        if(this.membership) {
            this.form = this.membership
            this.form.benefits = this.membership.benefits != null ? JSON.parse(this.membership.benefits) : []
            this.messageConfirm = 'Update Membership'
        }
    },
    methods: {
        ...mapActions('membership', ['storeMembership', 'updateMembership']),
        changeFiles(){
            this.form.image = this.$refs.image.files[0]
        },
        onSubmit () {
            this.loading = true
            const formData = new FormData()
                formData.append('name', this.form.name)
                formData.append('image', this.form.image)
                formData.append('price', this.form.price)
                formData.append('months', this.form.months)
                formData.append('payment_monthly', this.form.payment_monthly)
                formData.append('is_enable', this.form.is_enable)
                formData.append('period', this.form.period)
                formData.append('benefits', JSON.stringify(this.form.benefits))
            
            this.storeMembership(formData).then(() => {
                this.loading = false
                openNotification()
                this.$emit('close')
            })
        },
        onUpdate () {
            this.loading = true
            const formData = new FormData()
                formData.append('id', this.form.id)
                formData.append('name', this.form.name)
                formData.append('image', this.form.image)
                formData.append('price', this.form.price)
                formData.append('months', this.form.months)
                formData.append('payment_monthly', this.form.payment_monthly)
                formData.append('is_enable', this.form.is_enable)
                formData.append('period', this.form.period)
                formData.append('benefits', JSON.stringify(this.form.benefits))
            
            this.updateMembership({data: formData, id: this.form.id}).then(() => {
                this.loading = false
                openNotification()
                this.$emit('close')
            })
        }
    }
}
</script>